import React, { useState } from 'react';
import CompanyLayout from '../../components/company';
import { Card, Input, Button, Form, notification } from 'antd';
import { Link } from 'gatsby';
import SEO from '../../components/seo';

const Contact = (props) => {
    const [loading, setLoading] = useState(false);

    const [form] = Form.useForm();

    const handleSubmit = (values) => {
        setLoading(true)
        fetch(`${process.env.GATSBY_REALTYSTACK_API_URL}/workflow/message`, {
            method: 'POST', // *GET, POST, PUT, DELETE, etc.
            mode: 'cors', // no-cors, *cors, same-origin
            headers: {
              'Content-Type': 'application/json',
              'x-api-key': process.env.GATSBY_REALTYSTACK_PUBLIC_API_KEY,
              // 'Content-Type': 'application/x-www-form-urlencoded',
            },
            redirect: 'follow', // manual, *follow, error
            referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
            body: JSON.stringify(values) // body data type must match "Content-Type" header
        })
        .then(res => {
            notification.success({
                message: 'Request received',
                description: 'Your request has been recieved by our support team. If necessary you will be contacted. Thanks!',
            })
            setLoading(false)
        })
        .catch(error => {
            console.log(error)
            notification.error({
                message: 'Request error',
                description: 'Your request cannot be processed right now. Please try again later',
            })
            setLoading(false)
        })
    };

    return (
      <CompanyLayout {...props}>
        <SEO title="Contact" description='Company - Contact' />
        <h2 style={{fontSize:'28px', fontWeight:'400', lineHeight:'1.2', marginBottom:'12px', color:'#495057', whiteSpace:'prewrap'}}>
          Contact <span style={{fontWeight:600}}>Us</span>
        </h2>
        <div style={{height:'100%', paddingBottom:'150px'}}>
          <Card>
            <h4>Email</h4>
            <p>The best method of getting in contact with the RealtyStack team to to send an email to our support team at the email below.</p>
            <p><a mailto='support@realtystack.io'>support@realtystack.io</a></p>
            <br></br>
            <h4>Send a Message</h4>
            <p>You can also send us a message with your contact information and we will get back to you as soon as possible.</p>
            <br></br>
            <Form 
            onFinish={handleSubmit}
            layout='vertical'
            form={form}
            >
                <Form.Item
                label="Name"
                name="name"
                rules={[{ required: true, message: 'Please input your name' }]}
                >
                    <Input size='large' placeholder='Provide a name' />
                </Form.Item>   
                <Form.Item
                label="Email"
                name="email"
                rules={[
                    { required: true, message: 'Please provide an email' },
                    { type: "email", message: 'Not a valid email address' }
                  ]}
                >
                    <Input size='large' placeholder='Provide a email' />
                </Form.Item>  
                <Form.Item
                label="Phone Number"
                name="phone"
                rules={[{ required: true, message: 'Please input your phone number' }]}
                >
                    <Input size='large' placeholder='Provide a phone number' />
                </Form.Item> 
                <Form.Item
                label="Message"
                name="message"
                rules={[{ required: true, message: 'Please input a message' }]}
                >
                    <Input.TextArea size='large' rows={4} placeholder='Please provide a message'>
                    </Input.TextArea>
                </Form.Item>   
                <Form.Item>
                    <Button 
                    type="primary" 
                    size='large' 
                    onClick={() => form.submit()} 
                    style={{float:'right', background:'#43c463', border:'none'}}
                    >
                    Submit
                    </Button>
                </Form.Item>   
            </Form>
          </Card>
        </div>
      </CompanyLayout>
    )
}

export default Contact;
